@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2,h3,h4,h5 {
  color: #3D3E43;
}

ul,li,a,p {
  color: #595C69;
}
span {
  font-family: 'Montserrat', sans-serif !important;
}

.designWord {
  position: relative; 
}

.designWord {
  content:'';
  background-image: url('./underline.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 95%;
  text-align: center;
}

.designWordPartner {
  font-size: 36px;
}
.designWordPartner {
  content:'';
  background-image: url('./underline.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 100%;
  width: fit-content;
  line-height: 1.75;
}

.designWordPartner {
  font-size: 36px;
}
.designWordPartner {
  display: inline-flex;
  content:'';
  background-image: url('./underline.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 100%;
  width: fit-content;
  line-height: 1.75;
}

@layer components {
  h1 {
    @apply text-[32px] md:text-[48px] mb-2 font-semibold
  }
  h2 {
    @apply text-[22px] font-semibold
  }
}
.bm-burger-button {
  display: block;
  position: relative;
}
.is-open .bm-burger-button {
  transition: ease-out .3s;
  opacity: 0;
}
/* #react-burger-menu-btn {
  left: -10px;
  top: -10px;
  width: 40px;
  height: 40px;
} */
.bm-cross-button {
  transform: translateY(-50%);
  right: 24px !important;
  top:  -41px !important;
}
.bm-overlay,.bm-menu-wrap {
  left: 0;
  top: 0;
  background-color: #F2F2F2;
}
.bm-overlay {
  display: none;
}
.bm-menu-wrap {
  padding: 20px 20px;
  top: 82px;
}
.bm-item-list li,.bm-item-list div {
  padding: 10px 3px 10px 0;
}

.post-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}